// Here you can add other styles
.top_header{
    .custom_logo{
        width: 100px;
        object-fit: contain;
    }
    a{
        text-transform: capitalize;
        color:$text-gray;
    }
}
