// Global Class

.input-group>.form-control:focus,
.input-group>.form-select:focus {
    z-index: unset;
}

input {
    color: #404040;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    text-transform: unset;
}

.btn:hover {
    background-color: $bg-blue !important;
    color: #fff !important;
}

.form-control:focus {
    box-shadow: unset !important;
}

.pw_text {
    position: absolute;
    right: 30px;
    color: $text-light;
    top: 10px;
}

.form-control {
    height: 47px;
    padding-top: 0px;
    padding-bottom: 0px;
    border: 1px solid #ECECEC;
    box-shadow: inset 0px 1px 1px rgb(0 0 0 / 25%);
    border-top-right-radius: 8px !important;
    border-bottom-right-radius: 8px !important;
}

.auth .input-group-text {
    width: 60px;
    height: 47px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px 0px 0px 8px;
    box-shadow: inset 0px 1px 1px rgb(0 0 0 / 25%);
    border: 1.5px solid #B1AFAF !important;
}

.label-bg-v1,
.label_v1 span.input-group-text {
    background-color: $label-color-v1;
}

.label-bg-v2 {
    background-color: $label-color-v2;
}

.auth input::-webkit-input-placeholder {
    /* Edge */
    color: $text-lightv2;
    font-family: $font_primary;
    text-transform: unset;
    font-size: 18px;
    font-weight: 500;
}

.auth input:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: $text-lightv2;
    font-family: $font_primary;
    text-transform: unset;
    font-size: 18px;
    font-weight: 500;
}

.auth input::placeholder {
    color: $text-lightv2;
    font-family: $font_primary;
    text-transform: unset;
    font-size: 18px;
    font-weight: 500;
}

.formv2 label {
    font-weight: 500;
    font-size: 13px;
    line-height: 14px;
    text-transform: uppercase;
}

.emergency_contact .pg_heading {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}

.bg_input input,
.academic_edit input {
    background: #F1F1F1;
    border: 1px solid #E5E5E5;
    box-shadow: inset 0px 2px 2px rgb(0 0 0 / 25%);
    border-radius: 8px !important;
}

.bg_input_w {
    background: #FFFFFF !important;
}

.priority_block {
    input {
        width: 100% !important;
        border: none !important;
        box-shadow: none !important;
        
    }
}

.formv2.textarea,
.formv2 textarea {
    background: #FFFFFF;
    border: 1px solid #E5E5E5;
    box-shadow: inset 0px 2px 2px rgb(0 0 0 / 25%);
    border-radius: 8px;
    height: 65px;
    padding-top: 13px;
    color: #000000;
    font-style: italic;
}

::-webkit-file-upload-button {
    display: none !important;
}

input[type='file'] {
    color: #fff !important;
    font-size: 14px;

}

.custom_file_upload {
    text-align: center;
    vertical-align: middle;
    position: relative;
    border-radius: 12px;
    width: 575px;
    margin: 0 auto;
}

.custom_file_upload input,
.custom_file_upload input:focus {
    position: relative;
    z-index: 999;
    background: transparent;
    height: 100%;
    padding: 174px 243px 133px;
}

#customFileUpload {
    background: #D65F2E !important;
}

#impactDataImport {
    background: $bg-blue !important;
}

.upload_text {
    position: absolute;
    z-index: 999;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -100%);
    width: 100%;
}

.upload_text h6 {
    position: relative;
    top: 30px;
}

.form-check input {
    border-radius: 100%;
    background: #fff;
    border: 1px solid #ECECEC;
    box-shadow: inset 0px 4px 4px rgb(0 0 0 / 25%);
    width: 21px;
    height: 21px;
    font-weight: 400;
    font-size: 18px;
    border-radius: 100% !important;
}

.form-check label {
    font-weight: 400;
    font-size: 18px;
    margin-left: 11px;
}

.form-check-input:checked {
    background-color: $bg-blue !important;
    border-color: $bg-blue !important;
}

.auth_otp {
    margin: 25px 0px 15px !important;
}

.auth_otp input {
    width: 77px !important;
    height: 74px !important;
    border: 1px solid #ECECEC;
    box-shadow: inset 0px 2px 2px rgb(0 0 0 / 25%);
    border-radius: 8px;
    font-weight: 700;
    font-size: 50px;
    line-height: 60px;
    color: #000000;
    display: flex;
    align-items: center;
    justify-content: center;
}

.btn-auth-back,
.btn-auth-back:hover,
.btn-auth-back:focus {
    padding: 0 !important;
    background: transparent !important;
    border: 0;
    position: absolute;
    bottom: 19px;
    left: 35px;
    color: #000 !important;
}

.pw-input input {
    padding-right: 69px;
}

.notes {
    position: relative;
}

.notes_save,
.notes {
    position: relative;
    min-height: 259px;
}

.save_notes {
    position: absolute !important;
    top: 0;
    width: 100%;
    z-index: 9;
    background: #fff;
    display: none;
}

.notes_save .save_notes {
    display: block;
}

.react-datepicker-wrapper {
    position: relative;
}

.react-datepicker-wrapper:after {
    content: "";
    position: absolute;
    width: 25px;
    height: 25px;
    background-image: url(../assets/images/calender.svg);
    top: 11px;
    right: 19px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: right;
}

.react-datepicker__input-container input {
    background: #fff;
    width: 100%;
    height: 47px;
    padding: 0px 12px;
}

.react-datepicker__day:hover,
.react-datepicker__month-text:hover,
.react-datepicker__quarter-text:hover,
.react-datepicker__year-text:hover {
    background-color: #ECECEC !important;
}

.react-datepicker__header {
    background-color: #fff !important;
    border: 0 !important;
    padding: 0 !important;
}

.react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view--down-arrow,
.react-datepicker__navigation-icon::before {
    border-width: 2px 2px 0 0 !important;
    border-color: #000 !important;
    top: 18px !important;
}

.react-datepicker__current-month {
    height: 51px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: 1px solid #ebebeb;
    margin-bottom: 15px;
    font-size: 18px !important;
}

.react-datepicker__day.react-datepicker__day--030.react-datepicker__day--weekend.react-datepicker__day--outside-month,
.react-datepicker__day.react-datepicker__day--031.react-datepicker__day--outside-month {
    color: #828282 !important;
}

.react-datepicker__day-name {
    color: #404040 !important;
}

.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
    font-size: 15px !important;
    font-weight: 600 !important;
}

.react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::before,
.react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::after {
    display: none !important;
}

.react-datepicker {
    border-radius: 8px !important;
    border: 1px solid #ebebeb !important;
}

.react-datepicker-popper[data-placement^=bottom],
.react-datepicker__month-container {
    width: 380px !important;
    left: 30px !important;
}

.react-datepicker__day-names,
.react-datepicker__week {
    display: flex;
    justify-content: space-between;
}

.react-datepicker__day-names {
    margin-bottom: 0;
    display: flex;
    justify-content: space-between;
    padding-left: 10px;
    padding-right: 10px;
}

.bg_input.bg_primary .react-datepicker-wrapper {
    margin-bottom: 18px !important;
}

.academic_edit input {
    background: #F1F1F1 !important;
    border: 1px solid #E5E5E5 !important;
    box-shadow: inset 0px 2px 2px rgb(0 0 0 / 25%) !important;
    border-radius: 8px !important;
}

.contact textarea::-webkit-textarea-placeholder {
    font-style: normal !important;
    color: $text-lightv2;
}

.contact textarea:-ms-textarea-placeholder {
    font-style: normal !important;
    color: $text-lightv2;
}

.contact textarea::placeholder {
    font-style: normal !important;
    color: $text-lightv2;
}

.contact input::-webkit-input-placeholder {
    font-size: 16px;
}

.contact input:-ms-input-placeholder {
    font-size: 16px;
}

.contact input::placeholder {
    font-size: 16px;
}


.contact textarea {
    height: 200px;
}

.contact textarea,
.contact input {
    border-radius: 5px;
}

.rc-anchor-light {
    background: #fff;
}

.rc-anchor-error-message {
    display: none;
}

.recaptcha>div>div>div {
    margin: 0 auto;
}

.was-validated .form-control:invalid {
    border-color: #D65F2E !important;
}

.invalid-feedback {
    color: #D65F2E !important;
}

.form-range {
    background: none !important;
    border: unset !important;
    box-shadow: none !important;
}