@media only screen and (min-width : 300px) and (max-width: 767px) {
    .shape_r{
        width: 40px;
        height: 40px;
    }
    .invest_footer {
        text-align: center;
    }
    .strategies_block {
        margin-bottom: 30px;
    }
    .slide-top-content{
        padding: 35px 15px;
    }
    .body.dashboard{
        margin-left: 0px !important;
    }
    .chart_flex {
        margin-bottom: 30px;
    }
    .platform_card_blue .row > div {
        margin-bottom: 20px;
    }
    .platform_card_blue{
        padding: 15px;
    }
    .header_section.px-5 {
        padding: 0 !important;
    }
    .slide-top-content.text-center .d-flex {
        display: block !important;
    }
    .slick-prev {
        right: 30px;
        left: auto;
        top: 60px;
    }
    .slick-next {
        right: 0;
        top: 60px;
    }
    .student_block{
        margin-bottom: 30px;
    }
    .sync_slider .thumbnil{
        padding: 30px 15px 30px 15px;
    }
    .slide-top-content
    .body.dashboard{
        margin-left: 0px !important;
        padding-left: 0 !important;
    }
    .sidebar .sidebar-nav{
        width: 100%;
    }
    button.header-toggler {
        display: block;
    }
p {
    font-size: 14px;
    line-height: 20px;
}
.font16 {
    font-size: 14px;
}
.auth_body{
    margin-top:100px
}
.pg_heading{
    padding: 15px;
}
.pg_heading h6, .pg_heading .h6{
    font-size: 20px;
}
.auth_right_block {
    width: 100% !important;
}
.auth h1 {
    font-size: 20px;
}
.form-check label, .auth input{
    font-size: 14px;
}
.auth input::-webkit-input-placeholder { /* Edge */
    font-size: 14px;
  }
  
.auth input:-ms-input-placeholder { /* Internet Explorer 10-11 */
    font-size: 14px;
  }
.auth input::placeholder {
    font-size: 14px;
  }
  .auth .card {
    padding: 5px !important;
}
.auth .input-group-text{
    width: 50px;
    height: 40px;
}
.auth  .form-control{
    height: 40px;
}
.auth_right_block .card-body {
    padding: 20px !important;
}
.auth_right_block .card-body br {
    display: none;
}
.auth_otp > div {
    display: flex;
    justify-content: space-between;
}
.auth_otp input{
    width: 40px !important;
    height: 40px !important;
}
.btn-light{
    font-size: 15px;
    padding: 14px;
}
.section-space {
    padding: 20px 15px !important;
}
.checklist_password {
    margin-left: 0;
}
.reports_block, .report_right    {
    display: block !important;
}
.reports_block{
    position:relative;
}
.report_right .file-download {
    position: absolute;
    top: 10px;
    right: 10px;
}
.report_right .file-download svg {
    width: 20px;
}
.p-30 {
    padding: 30px 15px;
}
.emergency_contact_block {
    margin-left: 0 !important;
    margin-right: 0 !important;
}
.custom_file_upload input, .custom_file_upload input:focus{
    padding: 174px 104px 128px;
}
.custom_file_upload{
    width: 100%;
}
 ul.header-nav {
    justify-content: center;
    width: 100%;
}
.challange_block{
    padding: 15px;
}
.font-25{
    font-size: 20px !important;
}
.challange_inner_block svg {
    width: 30%;
}
.w-25{
    width:100% !important;
}
.dashboard .pg_heading {
    padding: 20px !important;
    display: block !important;
    text-align: center;
}
.dashboard_icon {
    margin-bottom: 20px;
}
.dashboard .section-space {
    padding: 50px 10px !important;
}
.dashboard .btn-orenge, .dashboard_des{
margin:0 !important;
}
.contact.form textarea {
    height: 100px;
}
}
@media only screen and (min-width : 768px) and (max-width: 1024px) {
    button.header-toggler {
        display: block;
    }
    .sidebar .sidebar-nav{
        width: 100%;
    }
}