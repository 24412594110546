//********************* Variables *********************//

// Color system

@import url("https://fonts.googleapis.com/css2?family=Lato:wght@100;300;400;700;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@600&display=swap");
// font-family: 'Lato', sans-serif;
$bg-gray: #404040;
$text-gray: #404040;
$text-light:#828282;
$text-lightv2:#B1AFAF;
$text-linkv1:#2F73E9;
$btn_gray:#777777;
$text-black:#000000;
$text-blackv2:#272727;
$text-light-black:#545454;
$label-color-v1:#D4E0E8;
$label-color-v2:#AFAFAF;
$bg-blue:#073858;
$bg-light-blue:#2C6387;
$bg-bluev2:#2D6488;
$bg-orenge:#DE6330;
$bg-orenge-light:#eeae87;
// font
$font_primary:'Lato', sans-serif;
$font_secondary:'Montserrat', sans-serif;
