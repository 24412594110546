// Global Class
body,
h1,
h2,
h3,
h4,
h5,
h6,
a,
span,
button {
    font-family: 'Lato', sans-serif;
}

body,
p {
    font-weight: 500;
    font-size: 18px;
}

p {
    color: #000;
    font-weight: 400;
    font-size: 18px;
    line-height: 25px;
}

a {
    text-decoration: none !important;
    transition: all 0.5s ease 0s;
}

a:hover,
.btn,
.btn:hover {
    transition: all 0.5s ease 0s;
}

.font-italic {
    font-style: italic;
}

.bg_primary {
    background-color: $bg-gray;
}

.bg-orenge {
    background-color: $bg-orenge;
}

.bg-light-blue {
    background-color: $bg-light-blue !important;
}

.bg-blue-v2 {
    background-color: $bg-bluev2 !important;
}

.bg-blue {
    background-color: $bg-blue;
}

.text_primary {
    color: $text-gray;
}

.text-light-black {
    color: $text-light-black;
}

.text-blackv2 {
    color: $text-blackv2;
}

.text-gray {
    color: $bg-gray;
}

.text-lightv2 {
    color: $text-lightv2;
}

.text_black {
    color: $text-black;
}

.border-radius {
    border-radius: 8px;
}

.btn_primary {
    background: $btn_gray;
    border-radius: 6px;
    padding: 13px 0px;
    box-shadow: 0px 2px 2px #00000040;
    border: 0;
}

.btn-confirm {
    background: #397949;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
    border-radius: 6px;
    padding: 16px 0px;
    color: #fff !important;
    font-weight: 600;
}

.btn-confirm svg {
    margin-left: 8px;
    width: 30px;
    height: 30px;
}

.btn-border {
    border: 2px solid #FFFFFF !important;
    border-radius: 6px;
    padding: 8px 58px;
    background: transparent !important;
}

.font16 {
    font-size: 16px;
}

.font14 {
    font-size: 14px;
}

.font18 {
    font-size: 18px;
}

.font20 {
    font-size: 20px;
    line-height: 24px;
}

.font-light {
    font-weight: 300;
}

.text_link {
    color: $text-linkv1;
}

.font-regular {
    font-weight: 400;
}

.font-medium {
    font-weight: 600;
}

.font-bold {
    font-weight: 700 !important;
}

.font-semibold {
    font-weight: 800 !important;
}

.font-black {
    font-weight: 900;
}

.p-30 {
    padding: 30px 30px;
}

.text-shadow {
    text-shadow: 1px 1px #40404021;
}

.border-none {
    border: none !important;
}

.footer {
    display: none;
}

/* custom scroll */
::-webkit-scrollbar {
    width: 6px;
    height: 6px;
}

::-webkit-scrollbar-track {
    box-shadow: inset 0 0 10px #fff;
    border-radius: 10px;
}

::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: #7f7f7f;
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2);
}

/* custom scroll */
.header.header-sticky .nav-link {
    color: $bg-gray !important;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    padding-bottom: 0;
}

.mobile_logo {
    width: 100px;
}

img.sidebar-logo {
    width: 180px;
    height: 65px;
    object-fit: contain;
    object-position: left;
    padding-left: 15px;
    cursor: pointer;
}

.logout svg {
    margin-left: 10px;
}

.header {
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}

.nav-link.logout {
    margin-left: 20px;
}

// .header {
//     margin-bottom: 17px;
// }
.body {
    padding-top: 17px;
}

.dashboard .container-lg {
    padding: 0 15px;
}

.card_heading {
    font-weight: 600;
    font-size: 25px;
    line-height: 30px;
    margin-bottom: 23px !important;
}

.small_heading {
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    text-transform: capitalize;
}

.font-25 {
    font-size: 25px !important;
}

.pg_heading {
    height: 107px;
    padding: 33px 37px;
    background-color: $bg-gray;
    margin-bottom: 15px;
}

.pg_heading.dashboard {
    min-height: 208px;
}

.pg_heading h6 {
    font-weight: 600;
    font-size: 30px;
    line-height: 37px;
    font-family: $font_secondary;
}

.section-space {
    padding: 50px 60px !important;
}

.section-inner-spacer {
    padding: 60px 0px !important;
}

.btn-light {
    background-color: #ECECEC !important;
    box-shadow: none !important;
    border-radius: 6px;
    color: #404040 !important;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    padding: 16px;
}

.btn-light:hover,
.btn-light:focus {
    background-color: $bg-orenge !important;
    box-shadow: none !important
}
 
.ideal {
    pointer-events: none !important;
}

.btn-blue-v2 {
    background: #2D6488 !important;
    border: 1.5px solid #2D6488 !important;
    border-radius: 8px !important;
    color: #fff !important;
    padding: 16px;
}

.btn-blue-v2 svg g,
.custom-btn svg g {
    fill: #fff !important
}

.btn-light svg {
    width: 30px;
    height: 30px;
}

.btn-orenge {
    background: #DE6330;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
    border-radius: 6px;
    padding: 16px 30px;
    border: 0 !important
}

.btn-blue {
    background-color: $bg-blue;
    color: #fff;
    padding: 16px 30px;
    border: 0 !important
}

.custom-btn {
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
    border-radius: 6px;
    padding: 16px 30px;
    border: 0 !important
}

.custom-btn svg {
    width: 30px;
    height: 30px;
}

.emergency_contact_block,
.custom_card,
.edit_cal,
.clear_cal,
.academic_clear,
.form_confirm {
    padding: 20px 30px;
    border: 1px solid #D9D9D9;
    border-radius: 8px;
    position: relative;
    padding-bottom: 40px !important;
}

.academic_clear {
    border: none !important
}

.edit_cal,
.clear_cal {
    margin-top: 1rem !important;
}

.reports_block,
.report_content {
    padding: 18px 16px;
    border-radius: 8px;
}

.report_content,
.insight-n-content {
    padding: 100px !important;
}

.report_content h6,
.insight-n-content h6,
.student_block h4,
.font40 {
    font-size: 40px;
}

.report_left svg {
    margin-right: 11px;
}

.checklist_password {
    margin-left: 40px;
}

.checklist_password p svg {
    margin-right: 10px;
}

.flex-header .n-content {
    margin-left: 16px;
}

.challange_block {
    padding: 50px 50px;
    border: 1px solid #D9D9D9;
    border-radius: 8px;
}

.challange_label {
    background-color: $label-color-v1 !important;
    padding: 22px;
    border-color: $label-color-v1;
    text-transform: capitalize;
}

.challange_label:hover {
    background: $bg-orenge-light !important;
    color: #000 !important;
    border-color: $bg-orenge-light;

}

// .challange_label:focus,
.challange_label.select {
    box-shadow: none !important;
    border-color: $bg-orenge !important;
    background-color: $bg-orenge !important;
    color: #fff !important;
}

.w-25 {
    width: 350px !important;
}

.body.dashboard {
    background: #faeadb;
    padding: 10px;
    padding-top: 15px;
    // margin-left: 35px !important;
    padding-right: 0;
}

// .dashboard .pg_heading {
//     padding-left: 85px !important;
//     padding-right: 85px;
// }
.header_user_detail h6 {
    margin-left: 13px !important;
}

.data_import_icon {
    width: 128px;
    height: 120px;
    border-radius: 100%;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
}

.data_import_icon h6 {
    font-weight: 700;
    font-size: 60px;
}

// Institution Information section show hide
.academic_clear {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    z-index: -1;
}

.edit_cal .academic_edit {
    opacity: 0;
    z-index: -1;
}

.edit_cal .academic_clear {
    opacity: 1;
    z-index: 1;
}

// .academic_edit .btn-confirm {
//     display: none;
// }
// .clear_cal.confirm .btn_confim_info {
//     display: none;
// }
// .clear_cal.confirm .btn-confirm {
//     display:block;
// }

.clear_cal .btn-confirm {
    display: none !important;
}

.form_confirm .btn_confim_info {
    display: none;
}

.academic_cal .btn-confirm {
    display: none;
}

.btn_edit {
  background-color: green;
  color: white;
  font-size: 16px;
  font-weight: normal;
  border: none;
  /* Add any other styles you want */
}
.btn-clear:hover,
.btn_edit:hover,
.btn_edit:focus,
.btn-clear:hover {
    background: transparent !important;
    color: #000 !important;
    box-shadow: none !important;
}

.form_section.clear {
    position: absolute;
    z-index: -1;
    background: #fff;
    top: 20px;
    opacity: 0;
}

.primary_edit .form_section.clear {
    display: block !important;
    opacity: 1;
    z-index: 9;
    margin-right: 30px;
}

.primary_contact .btn-confirm {
    display: none;
}

.primary_confirm .btn_orenge_confirm {
    display: none;
}

.primary_edit .form_section.edit {
    opacity: 0;
}

.primary_edit input {
    background: #fff;
}

.primary_cancel .btn-confirm {
    display: none;
}

.pg_content.contact {
    background: #0d0919 !important;
}

.contact {
    padding: 80px 0px;
}

.insight .pg_heading {
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
}

.student_block {
    padding: 50px 0px;
}

.sidebar-nav .nav-link.nav-group-toggle::after {
    background-image: url(../assets/images/sidebar_arrow.svg) !important;
}

html:not([dir=rtl]) .sidebar-nav .nav-group-items .nav-link {
    padding: 0;
}

button.header-toggler {
    display: none;
}

.header.header-sticky {
    padding-left: 0 !important;
}

.pg_header_dropdown html:not([dir=rtl]) .dropdown-toggle::after {
    margin-left: 9px;
    position: relative;
    top: 2px;
}

.pg_header_dropdown .dropdown-toggle::after {
    display: inline-block;
    vertical-align: 0.255em;
    content: "";
    border-top: 0.4em solid;
    border-right: 0.4em solid transparent;
    border-bottom: 0;
    border-left: 0.4em solid transparent;
    position: relative;
    top: 2px;
    left: 6px;
}

.pg_header_dropdown button.btn.btn-secondary.dropdown-toggle {
    height: 54px;
    width: 215px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #fff;
    font-size: 16px;
    font-weight: 500;
    box-shadow: 0px 2px 2px #00000040;
    border-radius: 6px !important;
}

.pg_header_dropdown button.btn.btn-secondary.dropdown-toggle:focus,
.pg_header_dropdown button.btn.btn-secondary.dropdown-toggle:hover {
    background: #fff !important;
    color: #000 !important;
}

.pg_header_dropdown .dropdown-menu.show {
    display: block;
    border: 1px solid #D9D9D9;
    border-radius: 8px;
    width: 100%;
    margin-top: 12px;
    padding: 14px 5px;
}

.pg_header_dropdown .dropdown-item {
    padding: 14px 20px;
    font-weight: 500;
    font-size: 16px;
    color: #000000;
}

.pg_header_dropdown .dropdown-item:hover {
    background-color: #FAEADC;
}

.active_btn {
    background: #D65F2E !important;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25) !important;
    border-radius: 6px;
    color: #fff !important;
}

// Chart Css
.chart_label {
    position: absolute;
    top: 36%;
    left: 50%;
    transform: translate(-50%, 40%);
    font-weight: bold;
}

.chart_top {
    padding-top: 35px;
}

.color_code {
    height: 15px;
    width: 15px;
    background: #000;
    display: inline-flex;
    border-radius: 100%;
    align-items: center;
    margin-right: 14px;
}

.progress-bg-blue {
    background: #2D6488;
}

.progress-bg-light-orenge,
.bg-light-orenge {
    background: #FAEADC;
}

.am {
    background: #000000;
}

.asian {
    background: #023254;
}

.black{
    background: #2C6387;
}

.parents {
    background: #000000;
}

.unknownv3 {
  background: #CCDDDD;
}

.bg_orenge {
    background: #D65F2E;
}

.bg_oreangev2 {
    background-color: #E6783A;
}

.islander{
  background: #83BADD
  }
.nonparents,
.progress-bg-orenge {
    background: #073858;
}

.white {
    background: #98CDEE; 
}

.hispanic {
    background: #5596C1;
}

.races {
    background: #BDE1F9;
}

.unknown {
    background: #CCDDDD;
}

.female,
.incoming {
    background: #C14918;
}

.male,
.returning,
.shape_bg_blue {
    background: #EF9157;
}

.nonbinary,
.near-completer {
    background: #ce7137;
}

.unknownv1,
.unknownv4 {
    background: #CCCCDD;
}

.progress {
    margin-right: 55px;
    border-radius: 10px;
    background: #ECECEC;
}

span.value {
    position: absolute;
    right: 18px;
    z-index: 999;
    top: -7px;
}

.progress_col {
    padding-left: 0;
    height: 16px;
    position: relative;
}

.chart_top.progressincol .chart_label {
    transform: translate(-50%, -10%);
}

.platform_card_blue {
    min-height: 190px;
    padding: 50px;
    border-radius: 8px;
    margin-top: 30px;
}

.progressv2 .progress {
    margin: 0;
    background: #fff;
    width: 175px;
}

.strategies {
    padding: 35px 40px;
    border: 1.5px solid #FFFFFF;
    border-radius: 8px;
}

.share_service {
    background: #D4E0E8;
    border-radius: 8px;
    padding: 45px 0px;
    margin-top: 30px
}

.count {
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
    color: #D65F2E;
    font-weight: 900;
    margin: 0 auto;
}

.strategies_block,
.student_footer_block {
    padding: 40px 0px;
    border-radius: 8px;
}

.shape_r {
    width: 60px;
    height: 60px;
    display: flex;
    border-radius: 100%;
}

.shape_flex .shape_r {
    margin-right: 20px;
}

.invetsed_header {
    border-right: 1px solid #000000;
}

.invetsed_header span.shape_r {
    margin: 0 auto;
}

.impact_icon {
    position: absolute;
    top: 30px;
    right: 30px;
}

.chart_v3 {
    padding-top: 60px;
}

.slide_icon svg {
    width: 50px;
    margin-right: 23px;
}

.slide-top-content {
    padding: 35px 80px;
    background: #F8F7F7;
    border-radius: 8px;
    margin-bottom: 16px;
    margin-top: 30px;
}

.sync_slider .thumbnil {
    background: #073858;
    border: 1px solid #000000;
    border-radius: 7.52212px;
    padding: 30px 16px 30px 40px;
}

.msg {
    padding: 15px;
}

.outgoing_msg,
.incoming_msg {
    padding: 15px 0px;
}

.outgoing_msg .msg {
    background: #D4E0E8;
    border-radius: 6px;
}

.incoming_msg .msg {
    background: #fff;
    border-radius: 6px;
}

.user_icon {
    margin-right: 20px;
}

.slide-block-msg {
    padding: 15px 0px;
}

.slide-block-msg::-webkit-scrollbar {
    width: 8px;
    height: 8px;
}

.slide-block-msg::-webkit-scrollbar-track {
    box-shadow: inset 0 0 10px #FAEADC;
    border-radius: 10px;
}

.slide-block-msg::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: #fff;
    box-shadow: inset 0 0 6px #FAEADC
}

.slide-block-msg {
    height: 600px;
    overflow-y: auto;
    padding-right: 40px;
}

.strategies .heading {
    margin-bottom: 30px;
}

button.slick-arrow {
    display: block !important;
    z-index: 999;
}

.slick-next {
    background-image: url(../assets/images/slick_next.svg) !important;
    background-size: contain !important;
    background-repeat: no-repeat !important;
    right: 30px !important;
}

.slick-next:before,
.slick-prev::before {
    display: none;
}

.slick-prev {
    background-image: url(../assets/images/slick_prev.svg) !important;
    background-size: contain !important;
    background-repeat: no-repeat !important;
    left: 30px !important;
}

.sidebar .sidebar-brand {
    background: #fff;
    justify-content: flex-start;
}

.fixed-btn {
    width: 215px !important;
    height: 54px !important;
    display: flex;
    align-items: center;
    justify-content: center;
}

.sidebar-brand svg {
    margin-left: 15px;
}

.auth.pg_content {
    background: #faeadb !important;
}

.top_header .navbar-brand svg {
    margin-right: 10px;
}

.priority_block {
    padding: 50px 56px;
}

.priority_slider_header {
    margin-bottom: 18px !important;
}

.priority_heading h6 {
    margin-bottom: 8px !important;
}

.priority_heading {
    margin-bottom: 37px;
}

.priority_slide {
    margin-bottom: 50px;
}

.priority_slide:last-child {
    margin-bottom: 0;
}

.chart_block_sm {
    width: 229.16px !important;
    height: 229.29px !important;

}



input[type="range"] {
    -webkit-appearance: none !important;
    height: 10px;
    background: #ECECEC;
    border-radius: 5px;
    background-image: linear-gradient(#EF9157, #EF9157) !important;
    background-repeat: no-repeat !important;
}

input[type="range"]::-webkit-slider-thumb {
    -webkit-appearance: none;
    height: 20px;
    width: 20px;
    border-radius: 50%;
    background: #EF9157 !important;
    cursor: pointer !important;
    box-shadow: 0 0 2px 0 #555;
    transition: background 0.3s ease-in-out !important;
}

input[type="range"]::-webkit-slider-runnable-track {
    -webkit-appearance: none !important;
    box-shadow: none !important;
    border: none !important;
    background: transparent;
}

input[type="range"]::-webkit-slider-thumb:hover {
    box-shadow: #EF915750 0px 0px 0px 8px;
}

input[type="range"]::-webkit-slider-thumb:active {
    box-shadow: #EF915750 0px 0px 0px 11px;
    transition: box-shadow 350ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
        left 350ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
        bottom 350ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.display-linebreak {
    white-space: pre-line;
}

.auth_body {
    .card {
        height: 528px;
    }
}

.not-partnered{
    display: flex;
    justify-content: center;
    p{
        width: 80%;
    }
}

 
.maito:hover,
.maito{
    color: #fff !important;
}
.upload_icon:hover path {
    fill: #FF0000; /* Change the fill color to the desired highlight color */
      transition: fill 0.3s ease; /* Add a smooth transition effect */
}

