
.sidebar{
    .sidebar-brand {
        background: #fff; 
        justify-content: flex-start;
    }
    .sidebar-nav{
        background-color:$bg-blue;
        padding-top: 30px;
        // width: 300px;
        .show{
            background: transparent;
        }
        li{
            .nav-group-items{
               a{
                padding-left: 45px !important;
               }
            }
        }
        .nav-link{
            color: #fff !important;
            font-weight: 500;
            font-size: 16px;
            padding:0px !important;
            padding: 20px 15px !important;
            // .side_icon{
            //     &:after{
            //         content: "";
            //         position: absolute;
            //         width: 8px;
            //         height: 8px;
            //         background: #EF9157;
            //         bottom: 2px;
            //         right: 18px;
            //         border-radius: 100%;
            //     }
            // }
            &.active{
                background: #FAEADC !important;
                color:#000000 !important;
            
                svg{
                    filter: invert(1);
                }
            }
            svg{
                width: 18px;
                margin-right: 20px;
            }
        }
    }
    .sidebar-toggler{
        display: none !important;
    }

}